export const environment = {
    production: true,
    pbatID: 'PBAT100445',
    auth: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkFkbWluIiwiaWF0IjoxNjIwMTMxNTYxfQ.7iTT5-7gnQVrabIb4af5wMRWWfPbWQfIZXBWvL8wA-A",
    title: "MOYEE SINGLE",

    roasterLatitude: "8.105416196996657",
    roasterLongitude: "36.98229328928205",
    wetmillLatitude: "8.99563030181815",
    wetmillLongitude: "38.7840813099731"
  };