import { Injectable } from '@angular/core';
import Farmer from '../classes/Farmer';
import Producer from '../classes/Producer';
import { environment } from '../environments/environment';
import farmers from '../../assets/global/data/fakeFarmers.json';
import { HttpService } from './http.service';

import Transaction from '../classes/Transaction';

@Injectable()
export class DetailsService {
  private httpService: HttpService;
  private farmer: Farmer;
  private producer: Producer;

  private details: any;
  private redCherries: any;

  public constructor(httpService: HttpService) {
    this.setHttpService(httpService);

    this.getHttpService().get(`${HttpService.URI_API}/valuedistribution/details/`).subscribe((i: any) => {
        this.setDetails(i.data.pbat);

        console.log("details", i);

        this.getHttpService().get(`${HttpService.URI_API}/transaction/all/`).subscribe((j: any) => {
          this.getHttpService().get(`${HttpService.URI_API}/valuedistribution/redcherries/`).subscribe((k: any) => {
            this.setProducer(new Producer(i.data.pbat, j.data.map((data: any) => {
              const transaction = k.data.find((t: any) => t.txnId == data.txid);
              if(transaction) {
                return new Transaction(data.txid, data.sender, data.receiver, transaction.quantity, transaction.price);
              } else {
                return new Transaction(data.txid, data.sender, data.receiver, 0, 0);
              }
            })));
          });
            console.error(j);
          });

        if (i.data.farmer) {
          this.getHttpService().get(`${HttpService.URI_API}/transaction/sender/`, {
              id: i.data.farmer.farmerId,
            }).subscribe((j: any) => {
              this.getHttpService().get(`${HttpService.URI_API}/valuedistribution/redcherries/`).subscribe((k: any) => {
                this.setRedCherries(k.data);
                const farmer = new Farmer(i.data.farmer, j.data.map((data: any) => {
                  const transaction = k.data.find((t: any) => t.txnId == data.txid);
                  console.log(transaction);
                  if(transaction) {
                    console.error("YAA")
                    return new Transaction(data.txid, data.sender, data.receiver, transaction.quantity, transaction.price);
                  } else {
                    return null;
                  }
                }).filter(e => e));

                this.getHttpService().get(`${HttpService.URI_API}/locations/farmer`, {
                  farmerid: farmer.getFarmerId()
                }).subscribe((info: any) => {
                  console.error(info);
                  farmer.setLongitude(+info.data.longitude);
                  farmer.setLatitude(+info.data.latitude);
                  this.setFarmer(farmer);
                });

              });
             
            });
        } else { //temporary fix
          const farmer: any = farmers.filter((farmer: any) => {
            return farmer.pbat == environment.pbatID;
          })[0];
          this.setFarmer(new Farmer(farmer.farmer, null));
        }
      });


      console.log({
        wetmillLong: this.getWetmillLongitude(),
        wetmillLat: this.getWetmillLatitude(),
        roasterLong: this.getRoasterLongitude(),
        roasterLat: this.getRoasterLatitude()
      });
  }

  public getTransactionByTxnId(txnId: string): any {
    return this.getRedCherries().find((e: any) => {
      return e.txnId == txnId;
    }) || {
      paymentSlip: ""
    };
  }

  public getWetmillLongitude(): number {
    return parseFloat(environment.wetmillLongitude);
  }

  public getWetmillLatitude(): number {
    return parseFloat(environment.wetmillLatitude);
  }

  public getRoasterLongitude(): number {
    return parseFloat(environment.roasterLongitude);
  }

  public getRoasterLatitude(): number {
    return parseFloat(environment.roasterLatitude);
  }

  /*
   * Getters & Setters
   */

  public getHttpService(): HttpService {
    return this.httpService;
  }

  public setHttpService(httpService: HttpService): void {
    this.httpService = httpService;
  }

  public getFarmer(): Farmer {
    return this.farmer;
  }

  public setFarmer(farmer: Farmer): void {
    this.farmer = farmer;
  }

  public getProducer(): Producer {
    return this.producer;
  }

  public setProducer(producer: Producer): void {
    this.producer = producer;
  }

  public getDetails(): any {
    return this.details;
  }

  public setDetails(details: any): void {
      this.details = details;
  }

  public getRedCherries(): any {
    return this.redCherries;
  }

  public setRedCherries(redCherries: any): void {
      this.redCherries = redCherries;
  }

}
